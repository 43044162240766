@import '../../styles/variables';

.patient-search {
  width: 100%;

  a {
    color: white;
  }

  .bold {
    font-weight: bold;
  }

  .footnote {
    button {
      background-color: $primary-color;
      text-transform: none;
      width: 200px;
      margin: 15px;
    }
  }

  .header {
    display: flex;
    justify-content: center;

    h1 {
      margin-bottom: 0;
    }
  }

  .discharge-date {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-bottom: 10px;

    .MuiInputBase-root {
      width: 200px;
      height: 35px;
      background-color: white;
    }
  }

  .normal-text {
    color: black;
    white-space: pre-line;
  }

  .green-text {
    font-weight: bold;
    color: green;
  }

  .orange-text {
    font-weight: bold;
    color: orange;
  }

  .red-text {
    font-weight: bold;
    color: red;
  }

  .notification {
    button {
      width: auto;
      margin: 0;
      background-color: transparent;
    }
  }

  .MuiTableCell-head {
    width: 16%;
  }

  .footnote {
    a {
      text-decoration: none;
    }

    button:hover {
      background-color: $primary-color;
    }
  }
}
