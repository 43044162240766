@import './styles/_variables';

.App {
  font-family: 'Roboto', sans-serif;
  height: 100%;

  .footer {
    height: 100px;
  }
}

body {
  background-color: $background-color;
}
