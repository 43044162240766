@import '../../styles/variables';

.patient-table {
  background-color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  overflow: auto;

  .filters {
    padding: 25px 15px;
    display: flex;
    justify-content: space-between;

    .status {
      margin-left: 25px;
    }
  }

  .sticky {
    position: sticky;
    background-color: white;
    left: 0px;
  }

  .MuiTableCell-root.sticky.second-col {
    min-width: 100px;
    left: 160px;
  }

  .MuiTableCell-root {
    a {
      text-decoration: none;
      color: black;
    }

    a:hover {
      text-decoration: underline;
    }

    .MuiSvgIcon-root {
      cursor: pointer;
      margin-top: 5px;

      path {
        pointer-events: none;
      }
    }

    .editing-notes {
      display: flex;
      align-items: center;

      input {
        width: 200px;
      }

      .MuiSvgIcon-root {
        margin: 0;
      }
    }

    textarea {
      font-family: inherit;
      font-size: inherit;
      border-radius: 5px;
      padding: 5px;
      width: 200px;
      resize: none;
    }
  }

  .MuiTableContainer-root {
    .MuiTableBody-root {
      position: relative;
      z-index: 1;

      .MuiTableRow-root {
        .saved {
          background-color: $tagged-background-color;
        }

        .readmission {
          background-color: $readmission-tag;
        }

        .patient-name {
          padding: 10px 5px;
          min-width: 150px;

          .inner {
            display: flex;
            align-items: center;
            margin-left: 10px;

            .MuiSvgIcon-root {
              margin: 0 10px;
              color: #20ce99;
            }
          }
        }
      }
    }
  }

  .notes {
    text-align: center;
  }

  .collapse {
    .MuiBox-root {
      display: flex;
      justify-content: center;

      .actions {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 10px;
      }
    }

    .MuiTableCell-root {
      padding: 0;
      border-bottom: none;
    }

    .MuiFormControl-root {
      width: 90%;
      margin: 0;

      textarea {
        width: 100%;
      }
    }

    .MuiCollapse-root {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }
  }

  .MuiSvgIcon-root.check {
    color: green;
  }

  .MuiSvgIcon-root.close {
    color: red;
  }

  .need-check-in {
    display: flex;
    justify-content: center;
    width: 120%;

    p {
      margin: 0;
      display: flex;
      align-items: center;
      min-width: 35px;
      margin-left: 5px;
      color: #3c3c43;
      font-size: 12px;
    }

    .date {
      margin: 0;
    }
  }

  .date {
    height: 34px;
    width: 93px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .notification {
    width: 95%;
    margin: 0 auto;
  }

  .red {
    background: rgba(255, 59, 48, 0.1);
  }

  .gray {
    background: rgba(151, 151, 151, 0.15);
  }

  .footnote {
    color: rgba(60, 60, 67, 0.6);
    text-align: center;
    margin: 10px 0;
  }
}

.table-filters {
  margin: 25px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .MuiButtonBase-root {
    height: 40px;
    text-transform: none;
    color: $primary-color;
    border-color: $primary-color;
    padding: 0;
  }

  .status-filter {
    .MuiButtonBase-root {
      width: 225px;
      p {
        color: black;
        margin: 0;
        margin-left: 5px;
      }
    }
  }

  .date-filters {
    display: flex;
    gap: 10px;

    .MuiFormControl-root {
      label {
        top: -8px;
      }

      .MuiInputLabel-shrink {
        top: 0;
      }
    }

    .MuiInputBase-root {
      input {
        padding: 8.5px 14px;
        width: 100px;
      }

      svg {
        color: $primary-color;
      }
    }

    .MuiFormLabel-root {
      color: $primary-color;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $primary-color;
    }
  }

  .reset {
    width: 100px;
  }

  .refresh {
    .loader {
      height: auto;
    }

    .MuiSvgIcon-root {
      height: 40px;
      width: 40px;
    }
  }

  .search-filter {
    .MuiInputBase-root {
      width: 225px;
      height: 40px;

      input::placeholder {
        font-size: 14px;
      }

      .MuiButtonBase-root {
        width: 35px;
        height: 35px;
        margin-right: 0;
      }
    }
  }
}

.status-menu {
  .MuiPaper-root {
    width: 200px;
  }
}

.table-filter {
  .MuiButtonBase-root {
    width: 162px;
  }

  p {
    margin: 0 5px;
    color: black;
  }

  .MuiInputBase-root {
    width: 200px;
    height: 50px;
  }
}

.table-filter-menu {
  h3 {
    margin: 5px 12px;
  }

  .MuiButtonBase-root {
    padding: 5px;
  }

  .MuiCheckbox-root.Mui-checked {
    color: $primary-color;
  }
}
