@import '../../../styles/variables';

.search {
  width: 95%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 25px;

  button {
    background-color: $primary-color;
  }

  button:hover {
    background-color: $primary-color;
  }

  input {
    text-indent: 20px;
  }

  .MuiTextField-root {
    button {
      width: 25px;
      height: 25px;
      color: white;
    }

    svg {
      width: 18px;
    }
  }

  .MuiInputBase-root {
    height: 100%;
    width: 100%;
    border: 0;
    border-radius: 5px;
    background-color: white;
  }

  .MuiTextField-root {
    height: 50px;
    width: 100%;
    border: 0;
    border-radius: 5px;
  }
}
