@import '../../styles/variables';

.invoicing {
  background-color: white;
  min-height: 600px;
  margin-top: 20px;
  border-radius: 8px;

  &.MuiContainer-root {
    padding: 50px;
  }

  h2 {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  .MuiTabs-root {
    margin-bottom: 50px;

    .Mui-selected {
      color: $primary-color;
    }
  }

  .MuiTableContainer-root {
    border: 1px solid black;
    border-radius: 5px;

    .MuiTableRow-root:hover {
      background-color: #f5f5f5;
    }
  }

  .invoice-table {
    width: 75%;
    margin: 0 auto;
    padding-top: 25px;

    .invoice-number:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    a {
      text-decoration: none;
      color: black;
    }

    .void a {
      color: red;
    }
  }

  .invoice-options {
    margin: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;

    .MuiInputBase-root {
      height: 40px;
      width: 215px;
    }

    .preview {
      background-color: $primary-color;
      text-transform: none;
      width: 100px;

      a {
        text-decoration: none;
        color: #f5f5f5;
      }
    }

    .search {
      border-color: $primary-color;
      width: 200px;
      margin-left: 100px;
      text-transform: capitalize;
      color: $primary-color;
    }
  }

  &.preview {
    .invoice-options {
      margin: 50px 0;
    }
  }

  .invoices {
    width: 200px;
    margin-bottom: 25px;

    .MuiInputBase-root {
      height: 35px;
    }
  }

  .invoice-summary {
    margin-bottom: 25px;

    .facility {
      min-width: 200px;
    }
  }

  .invoice-detail {
    margin-top: 50px;

    .MuiTable-root {
      .MuiTableCell-root {
        font-size: 12px;
      }
    }

    .info {
      margin-bottom: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      p {
        margin: 0;
        margin-bottom: 5px;
      }

      .section {
        display: flex;
        gap: 10px;
        margin-bottom: 25px;

        .description {
          .MuiInputBase-root {
            width: 410px;
          }
        }

        .facility-count {
          .MuiInputBase-root {
            width: 100px;
          }
        }

        .invoice-period {
          .MuiInputBase-root {
            width: 300px;
          }
        }
      }

      .MuiInputBase-root {
        height: 40px;
        width: 200px;
      }
    }

    .options {
      margin-top: 25px;
      display: flex;
      justify-content: center;
      gap: 50px;

      button {
        background-color: #20ce99;
        text-decoration: none;
        text-transform: none;
        color: white;
        height: 35px;
        width: 125px;

        &.danger {
          background-color: white;
          border-color: red;
          color: red;
        }
      }
    }
  }
}

.confirmation-modal {
  .MuiPaper-root {
    height: 150px;

    .MuiDialogActions-root {
      justify-content: center;
      margin-top: 25px;

      .MuiButton-outlinedPrimary {
        color: $primary-color;
        border-color: $primary-color;
      }

      .MuiButton-containedPrimary {
        color: white;
        background-color: $primary-color;
      }
    }
  }
}
