.interactiveForm {
  background-color: white;
  margin-top: 20px;
  border-radius: 8px;
}

.interactiveForm .notification {
  width: 95%;
  margin-top: 20px;
}

.interactiveForm .form-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.interactiveForm .section {
  margin-top: 15px;
  min-width: 700px;
}

.interactiveForm h3 {
  margin: 0;
  text-align: left;
}

.interactiveForm h3 span {
  color: red;
  font-weight: normal;
  font-size: 16px;
  margin-left: 10px;
}

.interactiveForm .section .MuiFormControlLabel-root {
  height: 30px;
  width: max-content;
}

.interactiveForm .MuiInputBase-root {
  height: 40px;
  width: 250px;
}

.interactiveForm .MuiInputBase-root .clear .MuiButtonBase-root {
  padding: 0;
}

.interactiveForm .single-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interactiveForm .patient-info {
  border-bottom: 1px solid #20ce99;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  z-index: 1;
}

.interactiveForm .patient-info p {
  margin-right: 1em;
}

.interactiveForm .patient-info a {
  color: #20ce99;
  text-decoration: none;
  margin-left: 5px;
}

.interactiveForm .patient-info .name a {
  color: black;
}

.interactiveForm .patient-info .name a:hover {
  text-decoration: underline;
}

.interactiveForm .checkInCall {
  display: flex;
  flex-direction: column;
}

.interactiveForm .checkInCall .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interactiveForm .checkInCall .heading p {
  margin: 0;
  color: lightslategray;
}

.interactiveForm .checkInCall .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.interactiveForm .checkInCall .row .info {
  display: flex;
  align-items: center;
}

.interactiveForm .checkInCall .row .info p {
  margin-left: 5px;
}

.interactiveForm .checkInCall .row .info .MuiSvgIcon-root {
  color: green;
}

.interactiveForm .checkInCall .row .info .circle {
  color: black;
  height: 13px;
  width: 13px;
}

.interactiveForm .discharge {
  display: flex;
  justify-content: space-between;
}

.interactiveForm .discharge .Mui-disabled {
  color: rgba(0, 0, 0, 0.54);
}

.interactiveForm .postDischargeQuestions .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}

.interactiveForm .postDischargeQuestions .row .question {
  display: flex;
}

.interactiveForm .postDischargeQuestions .row .question p {
  margin: 0;
  max-width: 400px;
}

.interactiveForm .postDischargeQuestions .row .notebox .MuiInputBase-root {
  width: 100%;
  height: 75px;
  margin-top: 10px;
}

.interactiveForm .postDischargeQuestions .row.directionColumn {
  flex-direction: column;
  align-items: normal;
}

.interactiveForm .postDischargeQuestions .directionColumn .MuiFormGroup-root {
  margin: 10px 25px;
}

.interactiveForm .postDischargeQuestions .serviceNeeds p {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.interactiveForm .dischargedInstructions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.interactiveForm .dischargedInstructions .MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.interactiveForm .medications {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.interactiveForm .medications .MuiFormGroup-root {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.interactiveForm .section .required {
  color: red;
}

.interactiveForm .MuiFormGroup-root .required {
  color: red;
  white-space: nowrap;
  margin-top: 5px;
}

.interactiveForm .communityServices .MuiFormControlLabel-root {
  height: 40px;
}

.interactiveForm .communityServices div {
  display: flex;
}

.interactiveForm .patientAppointments {
  margin: 5px 15px;
  width: 50%;
}

.interactiveForm .patientAppointments p {
  margin: 0;
}

.interactiveForm .patientAppointments .MuiTableContainer-root {
  border: 1px solid black;
  margin-bottom: 10px;
}

.interactiveForm .patientAppointments .MuiTableContainer-root .MuiTableHead-root .MuiTableCell-root {
  font-size: 14px;
  padding: 5px;
}

.interactiveForm .patientAppointments .MuiTableContainer-root .MuiTableBody-root .MuiTableCell-root {
  font-size: 12px;
  padding: 5px;
}

.interactiveForm .patientAppointments .MuiTableContainer-root .status {
  text-align: center;
}

.interactiveForm .patientAppointments h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interactiveForm .patientAppointments h3 .status {
  color: #20ce99;
  font-weight: bold;
  font-size: 16px;
}

.interactiveForm .patientAppointments h3 .urgent {
  color: red;
  font-weight: bold;
}

.interactiveForm .patientAppointments .row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 50px;
}

.interactiveForm .patientAppointments .row button {
  background-color: #20ce99;
  text-transform: none;
  width: 150px;
  margin-left: 15px;
  margin-left: 50px;
}

.interactiveForm .patientAppointments .row button:hover {
  background-color: #20ce99;
}

.interactiveForm .patientAppointments .row .MuiFormControl-root {
  margin-left: 5px;
}

.interactiveForm .patientAppointments .row .notification button {
  width: 0;
  background-color: transparent;
  margin-right: 10px;
}

.interactiveForm .patientAppointments .action-note .MuiInputBase-root {
  width: 500px;
  height: 50px;
  margin-left: 10px;
}

.interactiveForm .patientAppointments .assignee {
  width: 300px;
  margin-left: 10px;
}

.interactiveForm .patientAppointments .error {
  color: red;
}

.interactiveForm .patientAppointments .MuiFormControlLabel-root {
  margin-top: 5px;
  margin-left: 0;
}

.interactiveForm .patientAppointments .loader {
  height: inherit;
  margin-left: 5px;
}

.interactiveForm .followUp .MuiInputAdornment-root button {
  color: rgba(0, 0, 0, 0.54);
}

.interactiveForm .notes {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.interactiveForm .notes .MuiTextField-root {
  width: 700px;
  margin-top: 5px;
}

.interactiveForm .notes .MuiInputBase-root {
  width: 100%;
  height: 150px;
}

.interactiveForm .save {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 250px;
}

.interactiveForm .save button {
  background-color: #20ce99;
  text-transform: none;
  width: 150px;
  margin-bottom: 20px;
}

@media (max-width: 450px) {
  .interactiveForm .section {
    margin-top: 0;
    margin-left: 10px;
    width: fit-content;
  }
}
