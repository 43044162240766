.date-range .options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.date-range .options .MuiFormControl-root {
  margin: 0;
}

.date-range .options .MuiFormControl-root .MuiInputBase-root {
  font-size: 13px;
  width: 80px;
}

.date-range .options .MuiFormControl-root .MuiInputBase-root input {
  padding: 5px;
}
