@import '../../styles/variables';

.upload-processed {
  background-color: white;
  margin-top: 20px;
  border-radius: 8px;
  min-height: 500px;

  .notification {
    width: 500px;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: 0;
    padding-top: 20px;
    font-size: 24px;
    text-align: center;
  }

  .table-options {
    display: flex;

    .refresh {
      margin-left: auto;
      margin-right: 25px;

      .loader {
        height: auto;
      }

      .MuiSvgIcon-root {
        height: 40px;
        width: 40px;
        color: #20ce99;
      }
    }
  }

  .section {
    margin: 25px auto 50px auto;
    width: 95%;

    h1 {
      text-align: left;
    }
  }

  input {
    width: 100%;
    height: 25px;
    border: none;
    text-align: center;
    cursor: pointer;

    &.match {
      font-weight: bold;
      color: green;
    }

    &.error {
      font-weight: bold;
      color: red;
    }
  }

  input:hover {
    border-bottom: 1px solid #20ce99;
    margin-top: 1px;
  }

  input:focus {
    outline: none;
    border: 1px solid #20ce99;
    border-radius: 5px;
  }

  .MuiFormControl-root {
    width: 250px;
    margin: 10px 25px 0 25px;

    .MuiInputBase-root {
      height: 40px;
      width: auto;
    }
  }

  .MuiTableBody-root {
    .MuiTableRow-root.ingested {
      background-color: rgba(32, 206, 153, 0.1);
    }

    .MuiTableRow-root.ingested:hover {
      background-color: rgba(32, 206, 153, 0.1);
    }

    .MuiTableRow-root:hover {
      background-color: $background-color;
    }
  }

  table {
    thead {
      tr {
        border-bottom: 2px solid black;
      }
    }

    .button {
      width: 70px;
      margin-top: 0;
    }
  }

  .MuiTableContainer-root {
    .MuiTableCell-root {
      padding: 5px;

      .actions {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }

  .button {
    background-color: #20ce99;
    text-transform: none;
    width: 150px;
    height: 30px;
    margin-top: 25px;
    float: right;
  }

  .button:hover {
    background-color: #20ce99;
  }

  .collapse {
    .MuiBox-root {
      display: flex;
      margin: 15px;

      .actions {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 10px;

        .MuiSvgIcon-root {
          cursor: pointer;
        }
      }
    }

    .MuiCollapse-root {
      border-bottom: 1px solid rgba(224, 224, 224, 1);
    }

    .MuiTableCell-root {
      border-bottom: none;
      padding: 0;
    }

    .MuiFormControl-root {
      width: 100%;
      margin: 0;

      .MuiInputBase-root {
        height: 100px;
      }
    }

    &.MuiTableRow-root:hover {
      background-color: white;
    }
  }

  .MuiSvgIcon-root {
    &.check {
      color: green;
    }

    &.close {
      color: red;
    }
  }

  ::placeholder {
    color: red;
    opacity: 1;
  }
}
