.login-container {
  background-color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 10%;
}

.login-container .box {
  height: 300px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-container .box .athena-logo {
  margin-bottom: 100px;
  display: flex;
  align-self: center;
}

.login-container .athenahealthsignin {
  height: 35px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #20ce99;
  color: white;
  cursor: pointer;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
}

.login-container .athenahealthsignin .logo {
  height: 15px;
}
