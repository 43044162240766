@import '../variables';

.loader {
  display: flex;
  height: 50vh;
  justify-content: center;
  align-items: center;
}

.loader .MuiCircularProgress-root {
  color: $primary-color;
}
