@import '../variables';

.audit-history {
  display: flex;
  margin: 25px auto;
  text-align: center;
  justify-content: center;

  .table {
    min-width: 700px;

    h3 {
      margin: 0;
      text-align: left;
    }
  }

  button {
    color: $primary-color;
    text-transform: capitalize;
  }

  .MuiTableContainer-root {
    border: 1px solid black;
    margin-bottom: 10px;

    .MuiTableHead-root {
      .MuiTableCell-root {
        font-size: 14px;
        padding: 5px;
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        font-size: 12px;
        padding: 5px;
      }

      .MuiButtonBase-root {
        height: 20px;
      }
    }
  }
}

.audit-history-modal {
  textarea {
    width: 100%;
    height: 200px;
  }
}
