@import '../../styles/variables';

.visualization {
  background-color: white;
  min-height: 600px;
  margin-top: 20px;
  border-radius: 8px;
  padding: 25px;

  .chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    margin: 0 auto;

    h2 {
      margin-bottom: 25px;
    }
  }

  .recharts-polar-angle-axis-tick {
    text {
      font-size: 12px;
    }
  }

  .recharts-pie {
    text {
      font-size: 15px;
    }
  }
}

.MuiContainer-root.visualization {
  display: flex;
  flex-wrap: wrap;
  gap: 75px 25px;
}
