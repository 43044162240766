@import '../variables';

.radioSelect.MuiFormGroup-root {
  &.MuiFormGroup-root {
    display: flex;
  }

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  .required {
    color: $error;
    white-space: nowrap;
    margin-top: 5px;
  }
}
