.upload {
  background-color: white;
  height: 90vh;
  margin-top: 20px;
  border-radius: 8px;
}

.upload h1 {
  font-size: 24px;
  text-align: center;
}

.upload h3 {
  margin: 0 50px;
}

.upload .file-upload {
  margin: 10px 50px 50px 50px;
  display: flex;
}

.upload .button {
  background-color: #20ce99;
  text-transform: none;
  margin: 0 50px;
  padding: 0;
  width: 100px;
  height: 30px;
}

.upload .button:hover {
  background-color: #20ce99;
}

.upload a {
  text-decoration: none;
  color: white;
}

.upload .file-upload svg {
  color: #20ce99;
  font-size: 48px;
}

.upload .file-upload input {
  display: none;
}

.upload .file-upload .file-info {
  display: flex;
  align-items: center;
}

.upload .file-upload .MuiCircularProgress-root {
  margin-top: 10px;
}
