@import '../../styles/variables';

.reports {
  background-color: white;
  min-height: 600px;
  margin-top: 20px;
  border-radius: 8px;

  &.MuiContainer-root {
    padding: 50px;
  }

  h2 {
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .MuiTabs-root {
    margin: 25px 0;

    .Mui-selected {
      color: $primary-color;
    }
  }

  .summary {
    margin: 0 auto;
    width: 400px;
  }

  .bookings {
    margin: 0 auto;
    width: 400px;
  }

  .ic-metrics {
    .single {
      margin: 0 auto;
      width: 400px;
    }

    .comparison {
      width: 90%;
      margin: 0 auto;

      h4 {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 0;
      }

      .breakdown-toggle {
        display: flex;
        align-content: flex-end;

        .MuiTypography-root {
          font-size: 14px;
        }
      }
    }
  }

  .invoice {
    margin: 0 auto;
    width: 95%;

    .invoice-table {
      width: 75%;
      margin: 0 auto;

      .invoice-number:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .invoice-options {
      margin: 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;

      .MuiInputBase-root {
        height: 40px;
        width: 200px;
      }

      .generate {
        background-color: #20ce99;
        text-decoration: none;
        text-transform: none;
        color: white;
        height: 35px;
        width: 100px;
      }
    }

    .invoices {
      width: 200px;
      margin-bottom: 25px;

      .MuiInputBase-root {
        height: 35px;
      }
    }

    .invoice-summary {
      margin-bottom: 25px;

      .facility {
        min-width: 200px;
      }
    }

    .invoice-detail {
      .info {
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        p {
          margin: 0;
          margin-bottom: 5px;
        }

        .section {
          display: flex;
          gap: 10px;
          margin-bottom: 25px;

          .description {
            .MuiInputBase-root {
              width: 410px;
            }
          }

          .facility-count {
            .MuiInputBase-root {
              width: 100px;
            }
          }

          .invoice-period {
            .MuiInputBase-root {
              width: 300px;
            }
          }
        }

        .MuiInputBase-root {
          height: 40px;
          width: 200px;
        }
      }

      .options {
        margin-top: 25px;
        display: flex;
        justify-content: space-around;

        button {
          background-color: #20ce99;
          text-decoration: none;
          text-transform: none;
          color: white;
          height: 35px;
          width: 100px;
        }
      }
    }
  }

  .reconcile {
    margin: 0 auto;
    max-width: 1000px;

    .notification {
      margin: 25px;
    }

    .MuiTableRow-root {
      .MuiTableCell-root {
        button {
          background-color: $primary-color;
          text-transform: none;
          height: 30px;
          width: 70px;
        }
      }
    }
  }

  .MuiTableContainer-root {
    border: 1px solid black;
    border-radius: 5px;

    .MuiTableRow-root:hover {
      background-color: #f5f5f5;
    }

    .bold {
      font-weight: bold;
    }

    .italic {
      font-style: italic;
    }

    .row-line {
      border-top: 1px solid black;
    }

    .subcategory {
      font-style: italic;
    }

    .subcategory:first-child {
      padding-left: 35px;
    }

    .first-col {
      width: 225px;
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        border-bottom: none;
        max-width: 125px;
      }
    }

    .MuiTableCell-root .breakdown {
      display: flex;
      justify-content: space-around;
    }

    .date-picker {
      padding: 0;
      padding: 5px;
    }
  }
}
