.dashboard .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 30px;
}

.dashboard .header a {
  text-decoration: none;
}

.dashboard .header button {
  background-color: #20ce99;
  text-transform: none;
  margin-left: 15px;
}

.dashboard .header button:hover {
  background-color: #20ce99;
}

.dashboard .notification {
  margin-top: 10px;
}
