.nav {
  display: flex;
}

.nav .MuiToolbar-root img {
  width: 150px;
}

.nav .MuiToolbar-root button {
  background-color: white;
  border-radius: 5px;
  width: 100px;
  height: 30px;
  color: #20ce99;
  cursor: pointer;
  text-transform: none;
}

.nav .MuiToolbar-root .MuiTypography-root {
  color: black;
}

.nav .link {
  margin: 0 5px;
}

.nav .link:hover {
  border: 1px solid #20ce99;
}

.nav .user-dropdown {
  display: flex;
  color: black;
  margin-left: auto;
}

.nav .user-dropdown button {
  width: 100%;
  height: 100%;
}

.nav .user-dropdown .user-info {
  text-align: left;
}

.nav .user-dropdown button .MuiSvgIcon-root {
  margin-right: 10px;
  font-size: 40px;
}
