.patient-case {
  background-color: white;
  margin-top: 20px;
  border-radius: 8px;
}

.patient-case .form-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patient-case .case-action-notes.section {
  display: block;
  margin: 15px auto;
}

.patient-case.MuiContainer-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.patient-case .section {
  margin-top: 15px;
  min-width: 700px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.patient-case .patient-info {
  border-bottom: 1px solid #20ce99;
  background-color: white;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 1;
}

.patient-case .patient-info p {
  margin-right: 1em;
}

.patient-case h2 {
  display: flex;
  justify-content: center;
}

.patient-case h3 {
  margin: 0;
}

.patient-case .section .MuiFormControlLabel-root {
  height: 30px;
  width: max-content;
}

.patient-case .MuiInputBase-root {
  height: 40px;
  width: 225px;
}

.patient-case .MuiInputAdornment-root button {
  color: rgba(0, 0, 0, 0.54);
}

.patient-case .required {
  color: red;
  margin-top: 5px;
  margin-right: 10px;
}

.patient-case .case-subject .MuiInputBase-root {
  width: 400px;
}

.patient-case .notes .MuiTextField-root {
  width: 100%;
}

.patient-case .notes .MuiInputBase-root {
  width: 100%;
  height: 175px;
}

.patient-case .save {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  gap: 250px;
}

.patient-case .save button {
  background-color: #20ce99;
  text-transform: none;
  width: 150px;
  margin-bottom: 20px;
}
