@import '../variables';

.practice-select {
  .select {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 25px;

    .MuiInputBase-root {
      width: 200px;
      height: 40px;
    }
  }

  .submit {
    display: flex;
    justify-content: space-evenly;

    .MuiButtonBase-root {
      width: 100px;
      background-color: $primary-color;
      text-transform: capitalize;
    }
  }
}
