.patient-profile {
  background-color: white;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border-radius: 8px;
}

.patient-profile .disabled {
  pointer-events: none;
}

.patient-profile .notification {
  padding-top: 20px;
}

.patient-profile .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patient-profile .header button {
  background-color: #20ce99;
  text-decoration: none;
  text-transform: none;
  color: white;
  height: 35px;
  width: 150px;
  margin-right: 30px;
}

.patient-profile .header a {
  text-decoration: none;
  width: 150px;
  margin-right: 30px;
}

.patient-profile h2 {
  margin: 30px 2.5%;
}

.patient-profile .row {
  display: flex;
  gap: 20px;
  margin: 0 auto;
  width: 95%;
  margin-bottom: 20px;
}

.patient-profile .row .MuiFormHelperText-root {
  color: orange;
}

.patient-profile .input {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.patient-profile .input .Mui-disabled {
  -webkit-text-fill-color: black;
}

.patient-profile .input .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.patient-profile .input.invoice {
  width: 32%;
}

.patient-profile .input.invoice input:hover {
  cursor: pointer;
}

.date-wrapper .MuiFormControl-root {
  width: 100%;
}

.patient-profile .input p {
  margin: 0;
}

.patient-profile .input .MuiInputBase-root {
  height: 50px;
  border: 1px solid #dedede;
  border-radius: 5px;
  background-color: white;
  padding: 5px;
}

.patient-profile .input .MuiInputAdornment-positionEnd .MuiSvgIcon-root {
  font-size: 18px;
}

.patient-profile .input .clearDate {
  display: none;
}

.patient-profile .input:hover .clearDate {
  display: block;
}

.patient-profile .input .highlight {
  background-color: #ffebea;
  border-radius: 5px;
  height: 30%;
}

.patient-profile .interactive {
  display: flex;
  justify-content: center;
}

.patient-profile .interactive button {
  background-color: #20ce99;
  width: 30vw;
  text-decoration: none;
  text-transform: none;
  color: white;
}

.patient-profile .interactive a {
  text-decoration: none;
}

.patient-profile .interactive .MuiButtonBase-root:disabled {
  opacity: 75%;
}
.patient-profile .notes {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.patient-profile .notes .MuiTextField-root {
  width: 95%;
}

.patient-profile .notes .MuiInputBase-root {
  width: 100%;
  height: 200px;
  border: 1px solid #c6c6c8;
}

.patient-profile .submissionHistory {
  width: 95%;
  height: 250px;
  display: flex;
  flex-direction: column;
  border: 1px solid #c6c6c8;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 30px;
}

.patient-profile .submissionHistory h2 {
  margin-top: 10px;
  font-size: 16px;
}

.patient-profile .submissionHistory p {
  margin: 0;
}

.patient-profile .submissionHistory button {
  background-color: #20ce99;
  width: 80px;
  height: 25px;
  margin-top: 75px;
}

.patient-profile .submissionHistory .info {
  width: 220px;
}

.patient-profile .MuiInputBase-root button {
  color: #666666;
  margin-left: 0px;
}

.patient-profile .save {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  gap: 35px;
}

.patient-profile .save button {
  background-color: #20ce99;
  text-transform: none;
  width: 200px;
  display: flex;
  align-self: center;
  margin-bottom: 20px;
}

.patient-profile .MuiOutlinedInput-notchedOutline {
  border-color: white;
}

.appt-modal.MuiBox-root {
  padding: 24px;
  text-align: center;
  border-radius: 15px;
}

.appt-modal h3:first-of-type {
  margin-top: 0;
}

.appt-modal h3 {
  margin: 10px 0;
}

.appt-modal p {
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  color: #666666;
}

.MuiModal-root .MuiPickerStaticWrapper-root {
  width: 90%;
  border: 1px solid #c6c6c8;
  margin: 0 auto;
  max-height: 325px;
}

.MuiModal-root .MuiPickerStaticWrapper-root .availableDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
}

.MuiModal-root .MuiPickerStaticWrapper-root .availableDate .dot {
  display: flex;
  height: 5px;
  width: 5px;
  background-color: #20ce99;
  border-radius: 50%;
}

.MuiModal-root .times {
  width: 90%;
  min-height: 150px;
  border: 1px solid #c6c6c8;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  overflow: scroll;
  max-height: 200px;
}

.MuiBox-root .times .time {
  width: 100px;
  height: 40px;
  margin: 10px;
  border: 1px solid #c6c6c8;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiModal-root .times .selected {
  background-color: #20ce99;
  border: 0;
}

.MuiBox-root .times .MuiButtonBase-root {
  color: black;
}

.MuiModal-root .row {
  width: 90%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 20px;
}

.MuiModal-root .address-row {
  width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}

.MuiModal-root .address-row .MuiTextField-root {
  width: 100%;
}

.MuiModal-root .row .MuiTextField-root {
  width: 30%;
}

.MuiModal-root .buttons {
  display: flex;
  justify-content: center;
}

.MuiModal-root .buttons .MuiButtonBase-root {
  margin: 0 25px;
  width: 150px;
  height: 40px;
  background-color: #20ce99;
}

.MuiModal-root .buttons #back-button {
  background-color: white;
  color: black;
}

.MuiModal-root .loader {
  display: flex;
  justify-content: center;
}

@media (max-width: 450px) {
  .patient-profile .row {
    flex-direction: column;
  }

  .patient-profile .input {
    width: 100%;
  }

  .patient-profile .interactive button {
    width: 75%;
  }
}
