@import '../variables';

.case-action-notes .section {
  display: block;
}

.case-action-notes {
  margin: 5px 15px;
  width: 50%;

  h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .status {
      color: #20ce99;
      font-weight: bold;
      font-size: 16px;
    }

    .urgent {
      color: red;
      font-weight: bold;
    }
  }

  p {
    margin: 0;
  }

  .MuiTableContainer-root {
    border: 1px solid black;
    margin-bottom: 10px;

    .MuiTableHead-root {
      .MuiTableCell-root {
        font-size: 14px;
        padding: 5px;
      }
    }

    .MuiTableBody-root {
      .MuiTableCell-root {
        font-size: 12px;
        padding: 5px;
      }
    }

    .status {
      text-align: center;
    }
  }

  .row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-left: 50px;

    button {
      background-color: #20ce99;
      text-transform: none;
      width: 150px;
      margin-left: 15px;
      margin-left: 50px;
    }

    button:hover {
      background-color: #20ce99;
    }

    .MuiFormControl-root {
      margin-left: 5px;
    }

    .notification {
      button {
        width: 0;
        background-color: transparent;
        margin-right: 10px;
      }
    }
  }

  .action-note {
    .MuiInputBase-root {
      width: 500px;
      height: 100px;
      margin-left: 10px;
    }
  }

  .assignee {
    width: 300px;
    margin-left: 10px;
  }

  .error {
    color: red;

    .notification {
      width: 85%;
    }
  }

  .MuiFormControlLabel-root {
    margin-top: 5px;
    margin-left: 0;
  }

  .loader {
    height: inherit;
    margin-left: 5px;
  }
}
